import React, { useState } from 'react';
import { BsSearch } from 'react-icons/bs';
// import "./App.css"

function SearchBar() {
const [searchText, setSearchText] = useState('');

const handleInputChange = (event) => {
    setSearchText(event.target.value);
};

const handleSearch = () => {
    // Perform search functionality here
    console.log('Performing search for:', searchText);
};

return (
    <div style={{ position: 'absolute', top: 'self-center', right: '950px' }}>
    <div className="flex">
        <div className="relative">
        <div className="relative">
            <BsSearch
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-black"
            style={{ pointerEvents: 'none' }}
            />
            <input
            type="text"
            placeholder="Search..."
            value={searchText}
            onChange={handleInputChange}
            className="p-2 pl-10 pr-4 border-2 border-gray-400 rounded-full bg-gray-300 focus:outline-none"
            />
        </div>
        </div>
    </div>
    </div>
);
}

export default SearchBar;
