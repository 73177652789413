import React from 'react';

function Sidebar() {
const sidebarItems = [
    { text: 'Categories', icon: 'collection' }
];

return (
    <div className="w-1/6 px-4 h-screen">
    <div className="sticky top-20 p-4 bg-gray-100 rounded-xl w-full h-full">
    <ul className="flex flex-col overflow-hidden content-center justify-center">
    {sidebarItems.map((item, index) => (
    <li key={index} className="py-2 hover: shadow-md rounded, justify-center">
    <a className="truncate" href="#">
    <img className="w-7 mx-4 inline" src={`//cdn.jsdelivr.net/npm/heroicons@1.0.1/outline/${item.icon}.svg`} alt={item.text} />
    <span className="hidden sm:inline">{item.text}</span>
    </a>
    </li>
    ))}
    </ul>
    </div>
    </div>
);
}

export default Sidebar;
