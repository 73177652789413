import React from 'react';

function Title() {
return (
    <div className="flex flex-row justify-between items-center">
    <h1 className="text-3xl md:text-5xl mb-4 font-extrabold ml-4 mt-4">Mountain House Directory</h1>
    <div className="bg-blue-200 rounded-xl p-4 mr-4">
    <p className="text-xl text-right text-black">Please Contact Us With Your Business</p>
    </div>
    </div>
);
}

export default Title;
