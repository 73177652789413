import React from 'react';
import { HiMenu, HiViewGridAdd } from 'react-icons/hi';

function UtilBar() {
return (
    <div className="absolute top-0 right-4 left-0 p-4 h-20 bg-gray-100 rounded-md flex items-center justify-end">
    <button className="p-2 mr-2 bg-gray-500 text-white rounded hover:shadow-md">
        <HiMenu className="w-6 h-6" />
    </button>
    <button className="p-2 bg-gray-500 text-white rounded hover:shadow-md">
        <HiViewGridAdd className="w-6 h-6" />
    </button>
    </div>
);
}

export default UtilBar;
