import React from 'react';
import Title from './title';
import Sidebar from './sidebar';
import UtilBar from './utilbar';
import SearchBar from './search';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Title />
      <div className="relative flex">
        <Sidebar />
        <div className="relative flex-1">
          <div className="relative">
            <UtilBar />
            <div className="absolute inset-0">
              <SearchBar />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;